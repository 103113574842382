@import "../../foundation/setting";

.p-common-headline {
	margin-bottom: 3.5rem;
	color: inherit;
	font-weight: bold;
	font-size: 3.6rem;
	position: relative;
	@include line-height(36, 36);
	@include mq('max', 'xxl') {
		font-size: 4.2rem;
		@include line-height(42, 42);
	}
	@include mq('max') {
		margin-bottom: 1.4rem;
		@include line-height(34, 20);
	}
	@include mq('max', 'md') {
		font-size: 2.4rem;
	}
	.word {
		position: relative;
		display: inline-block;
		overflow: hidden;
		vertical-align: bottom;
		-webkit-transform: translate(0, 20%);
		transform: translate(0, 20%);
		transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		.o {
			display: inline-block;
			vertical-align: bottom;
			-webkit-transform: translate(0, 100%);
			transform: translate(0, 100%);
			transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		}
	}
	&.is-active {
		.word {
			transform: translate(0, 0);
			.o {
				transform: translate(0, 0);
			}
		}
	}
}


/*current-nav
---------------------------------------------------------- */
.p-side-nav {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	@include mq('max') {
		display: none;
	}
}

.p-side-nav__list {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.p-side-nav__item {
	&:not(:first-child) {
		margin-top: 2rem;
	}
	&[data-link-current="true"] {
		.p-side-nav__list-link {
			&:after {
				transform: scaleX(1);
			}
		}
	}
}

.p-side-nav__list-link {
	position: relative;
	display: block;
	display: block;
	width: 1.6rem;
	height: 1.6rem;
	margin: auto;
	border: solid 2px $color-main;
	border-radius: 50%;
	&:after {
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		width: 1.6rem;
		height: 2px;
		margin: auto;
		background-color: $color-main;
		content: '';
		transform: scaleX(0);
		transform-origin: 0 0;
		transition: transform 0.4s cubic-bezier(.39, .575, .565, 1);
	}
}


/*hero
---------------------------------------------------------- */
.p-mv {
	position: relative;
	height: 100vh;
	min-height: 100rem;
	@include mq('max', 'xxl') {
		max-height: 57vw;
		min-height: 70rem;
	}
	@include mq('max') {
		min-height: 78rem;
		min-height: 78rem;
	}
	@media screen and (max-width: 600px) {
		height: auto;
		min-height: auto;
		max-height: 100%;
	}
	.o-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		height: 100%;
		padding-top: 5rem;
		padding-bottom: 5rem;
		@include mq('max') {
			padding-bottom: 6rem;
			display: block;
		}
		@media screen and (max-width: 600px) {
			position: static;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	@media screen and (max-width: 600px) {
		position: static;
		margin-top: 5rem;
		margin-bottom: 5rem;
	}
}

.p-mv__inner {
	position: relative;
	@include mq('max') {
		margin-top: 0 !important;
		position: absolute;
		left: 0;
		top: 120px;
	}
	@media screen and (max-width: 600px) {
		position: static;
		padding-top: 8rem;
		z-index: 99;
		top: auto;
	}
}

/* .tablet.safari .p-mv,
.mobile.safari .p-mv {
	height: calc(var(--vh, 1vh) * 100);
} */

.p-mv__copy {
	width: 70rem;
	@include mq('max', 'xxl') {
		width: 40rem;
	}
	@include mq('max') {
		padding: 0 4%;
	}
	@media screen and (max-width: 600px) {
		max-width: 400px;
		width: 100%;
		img {
			width: 100%;
		}
	}
}

.p-mv__video {
    position: absolute;
    z-index: -1;
	right: -50rem;
	@include mq('min', 'xxl') {
		right: -42rem;
	}
	@include mq('max', 'xxl') {
		width: 100%;
		right: -28rem;
		max-width: 100%;
	}
	@include mq('max') {
		right: -20rem;
		bottom: 10%;
	}
	@include mq('max', 'md') {
		right: auto;
		left: -20%;
		bottom: 10%;
	}
	video {
		width: 100%;
		@include mq('max','md') {
			width: 150%;
		}
	}
	@media screen and (max-width: 600px) {
		position: static;
		transform: translate(-22%, 0);
		left: auto;
		bottom: auto;
	}
}

.p-mv__news {
	position: absolute;
	bottom: 5rem;
	width: 50%;
	max-width: 70rem;
	margin-top: 10rem;
	padding: 2rem 3rem;
	background-color: $color-white;
	border-radius: 99em;
	box-shadow: -2px -2px 5px #fff, 2px 2px 5px rgba(#202b5f, 0.3);
	@include mq('max', 'xxl') {
		width: calc(100% - 28rem);
		max-width: 100%;
	}
	@include mq('max') {
		width: calc(100% - 3rem);
		bottom: 3rem;
		left: 1.5rem;
		margin-top: 7rem;
		padding: 1.5rem;
		border-radius: 1.5rem;
		box-shadow: -1px -1px 2.5px #fff, 1px 1px 2.5px rgba(#202b5f, 0.3);
	}
	@media screen and (max-width: 600px) {
		position: static;
		margin: 0 auto;
		left: auto;
		bottom: auto;
		width: 100%;
	}
}

.p-mv__news-slider {
	position: relative;
	@include mq('min') {
		display: grid;
		grid-column-gap: 2rem;
		grid-template-rows: auto;
		grid-template-columns: auto auto;
	}
}

.p-mv__news-slider-link {
	@include mq('min') {
		display: grid;
		grid-column-gap: 2rem;
		grid-template-rows: auto;
		grid-template-columns: auto 1fr;
	}
	time {
		font-weight: 700;
		@include mq('max') {
			display: block;
		}
	}
}

.p-mv__news-slider-text {
	display: flex;
	align-items: center;
	@include line-height(26, 16);
}

.p-mv__news-slider-arrows {
	display: flex;
	align-items: center;
	@include mq('max') {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.p-mv__news-slider-arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	color: $color-white;
	border-radius: 50%;
	@include mq('max') {
		width: 2rem;
		height: 2rem;
	}
	&:not(:first-child) {
		margin-left: 1rem;
		@include mq('max') {
			margin-left: 0.5rem;
		}
	}
	&.slick-disabled {
		cursor: default;
		.c-arrow-circle {
			background-color: #eeeef0;
		}
	}
	@include mq('min') {
		&:not(.slick-disabled):hover {
			.c-icon-arrow-left {
				animation: hover-back 350ms forwards ease-out;
			}
			.c-icon-arrow-right {
				animation: hover 350ms forwards ease-out;
			}
		}
	}
	.c-arrow-circle {
		width: 100%;
		height: 100%;
	}
}

.p-mv__contact {
	position: absolute;
	right: 1.5rem;
	bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4rem;
	height: 4rem;
	background-color: $color-main;
	border-radius: 50%;
	@include mq('min') {
		display: none;
	}
	.c-icon-contact {
		font-size: 2rem;
		color: $color-white;
	}
}

/*scroll
---------------------------------------------------------- */
.p-mv__scroll {
	position: absolute;
	bottom: 2rem;
	width: 8rem;
	margin: auto;
	text-align: center;
	left: -10rem;
	svg {
		width: 1.7rem;
		fill: $color-main;
	}
}

.p-mv__scroll-text {
	display: block;
	font-weight: 700;
	font-size: 1.2rem;
	@include line-height(12, 12);
}


/*our-mind
---------------------------------------------------------- */
.p-mind {
	padding: 14rem 0;
	@include mq('max', 'xxl') {
		padding: 5rem 0 10rem;
	}
}

.p-mind__content {
	@include mq('min', 'xl') {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
	}
}

.p-mind__slider {
	@include mq('min', 'lg') {
		grid-column: 1/6;
	}
	@include mq('max', 'xl') {
		text-align: center;
	}
	img {
		width: 100%;
	}
}

.p-mind__body {
	position: relative;
	z-index: 0;
	@include mq('min', 'lg') {
		grid-column: 7/12;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	@include mq('max', 'xl') {
		padding: 3rem 0 0 4rem;
	}
	@include mq('min', 'xl') {
		.c-button-lv3 {
			margin-left: 0 !important;
		}
	}
}

.p-mind__copy {
	margin-bottom: 3rem;
	font-weight: 700;
	font-size: 2.8rem;
	@include line-height(48, 28);
	@include mq('max', 'xxl') {
		br {
			display: none;
		}
	}
	@include mq('max', 'xl') {
		margin-bottom: 1.5rem;
		font-size: 1.8rem;
	}
	@include mq('max', 'md') {
		font-size: 1.5rem;
	}
}

.p-mind__kanji-headline {
	position: absolute;
    top: 0;
    left: 24rem;
    z-index: -1;
    font-size: 20rem;
    font-weight: 100;
    color: #fff;
    letter-spacing: 1.6rem;
    line-height: 1;
    width: 100%;
	@include mq('max', 'xl') {
		width: auto;
		font-size: 15rem;
		line-height: 1;
		top: -9vw;
		right: auto;
		left: 0;
		writing-mode: vertical-rl;	
	}
	@include mq('max', 'md') {
		font-size: 20vw;
	}
}

.p-mind__text {
	margin-bottom: 5rem;
	@include mq('max') {
		margin-bottom: 2.5rem;
	}
}

/*our-features
---------------------------------------------------------- */
.p-features {
	position: relative;
	color: $color-white;
	z-index: 9;
}

.p-features__inner {
	margin: 0 12rem;
	@include mq('max') {
		margin: 0;
	}
}

.p-features__illust {
	position: absolute;
	&.-pattern1 {
		top: 10rem;
		left: 7rem;
	}
	&.-pattern2 {
		bottom: 0;
		right: 4rem;
	}
	@include mq('max') {
		&.-pattern1 {
			top: 5rem;
			left: 0rem;
		}
		&.-pattern2 {
			right: 1rem;
		}
	}
	@include mq('max', 'lg') {
		&.-pattern1 {
			width: 18rem;
		}
		&.-pattern2 {
			width: 5rem;
			right: 0;
		}
	}
	@include mq('max', 'md') {
		&.-pattern1 {
			top: 2rem;
		}
	}
}

.p-features__content {
	padding: 18rem 0 6rem 0;
	.p-common-headline {
		justify-content: center;
	}
	@include mq('max', 'md') {
		padding: 11rem 0 6rem 0;
	}
}
.p-features__text-item {
	background-color: $color-white;
	margin-right: 14rem;
	margin-left: 14rem;
	text-align: center;
	font-weight: $weight-bold;
	padding: 3rem;
	font-size: 2.8rem;
	color: $color-main;
	@include mq('max', 'xxl') {
		font-size: 2rem;
	}
	@include mq('max') {
		margin-right: 0;
		margin-left: 0;
		font-size: 1.8rem;
	}
	@include mq('max', 'md') {
		font-size: 1.4rem;
		padding: 1rem 3rem;
	}
}

.p-features__kanji-headline {
	position: absolute;
    top: 20%;
	left: 50%;
	transform: translate(-50%, -50%); 
	font-size: 20rem;
	font-family: $font-base;
	font-weight: $weight-thin;
	color: $color-white;
	letter-spacing: 1.6rem;
	opacity: 0.3;
	width: 100%;
	text-align: center;
	@include mq('max') {
		top: 20rem;
		font-size: 10rem;
	}
	@include mq('max', 'md') {
		top: 13rem;
	}
}

/*our-solution
---------------------------------------------------------- */
.p-solution {
	width: 100%;
	max-width: calc(140rem + 28rem);
	margin: auto;
	padding: 8rem 14rem 10rem 14rem;
	@include mq('max', 'xxl') {
		max-width: auto;
		padding: 8rem 7rem 10rem 7rem;
	}
	@include mq('max') {
		padding: 20rem 0 6.5rem;
	}
	.o-wrapper-md {
		position: relative;
	}
}

.p-solution__content {
	position: relative;
	margin-bottom: 12rem;
	&::before {
		content: "";
		position: absolute;
		top: 38rem;
		left: 4rem;
		width: 478px;
		height: 441px;
		background: url(/assets/img/top/p_solution_back_img.svg);
		transform: rotate(35deg);
		z-index: -1;
		@include mq('max', 'xl') {
			content: none;
		}
	}
}

.p-solution__header {
	position: relative;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-row-gap: 60px;
}

.p-solution__header-headline {
	position: relative;
	padding-left: 8rem;
	grid-column: 1/5;
	align-self: center;
	@include mq('max', 'xl') {
		padding-left: 0;
		grid-column: 1/11;
	}
}

.p-solution__header-img {
	position: relative;
	grid-column: 6/11;
	@include mq('max', 'xl') {
		grid-column: 1/11;
	}
}

.p-solution__header-text {
	@include mq('max', 'xl') {
		width: 81%;
	}
}

.p-solution__kanji-headline {
	position: absolute;
	top: -4rem;
	left: -5rem;
	z-index: -1;
	writing-mode: vertical-rl;
	font-weight: $weight-thin;
	color: $color-white;
	font-size: 20rem;
	letter-spacing: 0.16em;
	line-height: 1;
	height: 510px;
	@include mq('max') {
		font-size: 15rem;
		top: 29rem;
		right: 0rem;
	}
}

.p-solution__list-wrap {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		bottom: -1rem;
		right: 25rem;
		width: 478px;
		height: 441px;
		background: url(/assets/img/top/p_solution_back_img.svg);
		transform: rotate(10deg);
		z-index: -1;
		@include mq('max', 'xl') {
			content: none;
		}
	}
}

.p-solution__list {
	position: relative;
	z-index: 1;
	display: grid;
	grid-column-gap: 7rem;
	grid-row-gap: 7rem;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 1fr);
	@include mq('max', 'xxl') {
		grid-column-gap: 1rem;
	}
	@media screen and (max-width: 1420px) {
		grid-template-columns: 1fr;
	}
}

.p-solution__header {
	margin-bottom: 9rem;
	@include mq('max', 'xxl') {
		margin-bottom: 1rem;
	}
	@include mq('max') {
		margin-bottom: 1.5rem;
	}
}

.p-solution__list-headline {
	position: absolute;
	top: -3rem;
}

.p-solution__list-headline-ja {
	font-weight: 700;
	font-size: 2rem;
	@include line-height(28, 20);
	@include mq('max') {
		margin-bottom: 1rem;
		font-size: 1.4rem;
	}
}

.p-solution__item {
	&.\--design-firm {
		.p-solution__item-inner {
			padding-top: 8rem;
		}
	}
	&.\--school-management,
	&.\--creators-agent {
		.p-solution__item-inner {
			padding-top: 13rem;
		}
	}
	@include mq('max') {
		&.\--design-firm,
		&.\--school-management,
		&.\--creators-agent {
			.p-solution__item-inner {
				padding-top: 8rem;
				padding-bottom: 3rem;
			}
		}
	}
}

.p-solution__item-inner {
	position: relative;
	padding: 3rem;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
	@include mq('max','xxl') {
		padding: 5rem 3rem;
	}
	@include mq('max') {
		padding: 2rem 2rem 4rem;
		border-width: 0.3rem;
	}
}

.p-solution__list-headline-en {
	font-weight: 700;
	font-family: $font-en;
	font-size: 4.8rem;
	line-height: 1;
	@include mq('max') {
		font-size: 3.8rem;
		span:not(.o-effect-sprit-word) {
			display: none;
		}
	}
}

.p-solution__img {
	position: relative;
	width: 100%;
	height: 100%;
	margin-right: -5rem;
	@include mq('max') {
		width: 100%;
		margin-right: 0;
		text-align: center;
	}
	&.is-active {
		.p-solution__img-year {
			transform: rotate(0.0001deg) scale(1);
		}
		.p-solution__img-around {
			transform: rotate(0.0001deg) scale(1) rotateZ(0deg);
			opacity: 1;
			transition-delay: 0.8s;
		}
		.p-solution__img-line {
			transform: rotate(0.0001deg) scale(1) rotateZ(0deg);
			opacity: 1;
			transition-delay: 1.6s;
		}
	}
}

.p-solution__img-year,
.p-solution__img-around,
.p-solution__img-line {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transition-timing-function: e-oQuad;
	transition-duration: 1.5s;
	transition-property: transform opacity;
	text-align: center;
}

.p-solution__img-year {
	transform: rotate(0.0001deg) scale(0);
	position: relative;
}

.p-solution__img-around {
	transform: rotate(0.0001deg) scale(0.3) rotateZ(-15deg);
	opacity: 0;
}

.p-solution__img-line {
	transform: rotate(0.0001deg) scale(0.3) rotateZ(-15deg);
	opacity: 0;
}

.p-solution__list-text {
	margin-bottom: 2rem;
	z-index: 1;
	@include mq('max') {
		font-size: 1.1rem;
	}
}

.p-solution__illust {
	position: absolute;
	&.\-pattern1 {
		top: -6rem;
		right: -21rem;
		z-index: -1;
		@include mq('max') {
			width: 230px;
			top: -13rem;
			right: 0;
			z-index: -1;
		}
	}
	&.\-pattern2 {
		bottom: -4.5rem;
		right: 52%;
		@include mq('max', 'xl') {
			bottom: 0rem;
			right: 90%;
		}
		@include mq('max', 'md') {
			bottom: -5%;
			right: 90%;
			width: 9%;
		}
	}
	&.\-pattern3 {
		top: -10rem;
		left: -2rem;
	}
	&.\-pattern4 {
		bottom: 0;
		left: -6rem;
		@media screen and (max-width: 1420px) {
			bottom: -2.4rem;
		}
	}
	&.\-pattern5 {
		bottom: 6rem;
		right: -2rem;
		@media screen and (max-width: 1420px) {
			bottom: 0;
		}
	}
}



/*works
---------------------------------------------------------- */
.p-works {
	position: relative;
	padding: 10rem 0;
	@include mq('max') {
		padding: 5rem 0;
	}
	.o-wrapper {
		position: relative;
	}
}

.p-work__illust {
	position: absolute;
	z-index: -1;
	&.\-pattern1 {
		top: -3rem;
		right: -3rem;
		@include mq('max', 'md') {
			top: -9rem;
			right: -3rem;
			width: 188px;
		}
	}
	&.\-pattern2 {
		bottom: -16%;
		left: -14rem;
		@include mq('max') {
			width: 200px;
			bottom: -10rem;
			left: -4rem;
		}
	}
}

.p-works__inner {
	position: relative;
	z-index: 1;
	max-width: 2000px;
	margin: 0 auto;
	padding: 0 7rem;
	@include mq('max') {
		padding: 0 2rem;
	}
}

.p-works__kanji-headline {
	position: absolute;
	top: -3rem;
	left: -7rem;
	z-index: -1;
	font-weight: $weight-thin;
	color: $color-white;
	font-size: 20rem;
	line-height: 1;
	height: 510px;
	@include mq('max') {
		font-size: 20vw;
		left: 2rem;
		line-height: 1;
		text-align: left;
		height: auto;
		width: auto;
	}
}

.p-works__text {
	margin-bottom: 6rem;
	font-size: 2.4rem;
	text-align: center;
	@include line-height(42, 24);
	@include mq('max', 'xxl') {
		text-align: center;
		p {
			display: inline-block;
			text-align: left;
		}
	}
	@include mq('max') {
		margin-bottom: 4rem;
		padding-top: 2.5rem;
		padding-left: 9.5rem;
		font-size: 1.6rem;
	}
	@include mq('max', 'md') {
		padding-left: 0;
		width: 80%;
		margin: 0 auto;
		margin-bottom: 4rem;
	}
}

.p-works__list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 1.6rem;
	grid-column-gap: 1.6rem;
	padding: 2rem;
	background-color: $color-white;
	@include mq('max') {
		grid-template-columns: 1fr;
	}
}

.p-works__list-item {
	@include mq('max') {
	}
}

.p-works__list-item {
	@include mq('max', 'xxl') {
		&:nth-child(5) {
			display: none;
		}
	}
	&.is-active {
		.p-works__list-img-inner {
			img {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}

.p-works__list-link {
	position: relative;
	@include mq('min') {
		&[href]:hover {
			.c-icon-arrow-right {
				animation: hover 350ms forwards ease-out;
			}
		}
	}
	.c-arrow-circle {
		position: absolute;
		right: 2rem;
		bottom: 2rem;
		z-index: 1;
		color: $color-white;
		background-color: $color-main;
		@include mq('max') {
			right: 1rem;
			bottom: 1rem;
		}
	}
}

.p-works__list-img-inner {
	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #ddd;
	@include mq('min') {
		grid-area: areaB;
		padding-top: 75%;
	}
	@include mq('max') {
		padding-top: 75%;
	}
	img {
		@include fit-full();
		@include object-fit(cover, top center);
		z-index: -1;
		transform: scale(1.2);
		opacity: 0;
		transition: transform 1s cubic-bezier(0.36, 0.14, 0, 1), opacity 0.5s linear;
	}
}

.p-works__bg-title {
	position: absolute;
	right: 0;
	bottom: 6rem;
	left: 0;
	z-index: -1;
	margin: auto;
	width: 100vw;
	margin: 0 calc(50% - 50vw);
	@include mq('max') {
		bottom: 8rem;
	}
	fill: $color-main;
}

/*media-management
---------------------------------------------------------- */
.p-media {
	padding: 10rem 0;
	border-top: 1px solid $color-white;
	@include mq('max') {
		padding: 5rem 0;
	}
}

.p-media__inner {
	position: relative;
	@include mq('min', 'lg') {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
	}
}

.p-media__header {
	grid-column: 1/4;
	@include mq('max', 'xxl') {
		grid-column: 1/6;
		padding-right: 4rem;
	}
	@include mq('max') {
		margin-bottom: 2rem;
		padding-right: 0;
	}
}

.p-media__illust {
	position: absolute;
    right: -24rem;
	@include mq('max') {
		display: none;
	}
}

.p-media__list {
	grid-column: 5/11;
	display: grid;
	grid-column-gap: 0.2rem;
	grid-template-rows: auto;
	grid-template-columns: repeat(2, 1fr);
	@include mq('max', 'xxl') {
		grid-column: 6/11;
	}
	@include mq('max') {
		grid-row-gap: 0.2rem;
		grid-template-columns: 1fr;
	}
}

.p-media__list-link {
	position: relative;
	display: block;
	padding: 4rem 4rem 6.5rem;
	color: $color-white;
	background-color: #0073c4;
	@include mq('min') {
		&[href]:hover {
			
		}
	}
	@include mq('max') {
		padding: 2rem;
	}
	.c-arrow-circle {
		position: absolute;
		right: 4rem;
		bottom: 3rem;
		color: #0073c4;
		background-color: $color-white;
		@include mq('max', 'xxl') {
			right: 3rem;
		}
		@include mq('max') {
			right: 1rem;
			bottom: 1rem;
		}
	}
}

.p-media__list-logo {
	margin-bottom: 2rem;
	@include mq('max') {
		margin-bottom: 1rem;
		img {
			width: auto;
			height: 2.9rem;
		}
	}
}


/*relation
---------------------------------------------------------- */
.p-relation {
	padding-bottom: 10rem;
}
.p-relation__list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
	@include mq('max') {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-row-gap: 1rem;
	}
}

.p-relation__list-link {
	position: relative;
	background-color: $color-white;
	padding: 4rem;
	text-align: center;
	@include mq('max') {
		padding: 2rem;
	}
	.c-arrow-circle {
		position: absolute;
		right: 4rem;
		bottom: 3rem;
		@include mq('max', 'xxl') {
			right: 3rem;
		}
		@include mq('max') {
			right: 1rem;
			bottom: 1rem;
		}
	}
}

.p-relation__list-text {
	margin-bottom: 4rem;
	@include mq('max') {
		margin-bottom: 2rem;
	}
}

.p-relation__list-logo {
	margin-bottom: 2rem;
}


/*SDGs
---------------------------------------------------------- */
.p-sdgs {
	padding-bottom: 10rem;
}

.p-sdgs__inner {
	position: relative;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-row-gap: 0.2rem;
}

.p-sdgs__illust {
	position: absolute;
	left: -19rem;
	@include mq('max') {
		display: none;
	}
}

.p-sdgs__header {
	grid-column: 1/7;
	align-self: center;
	@include mq('max', 'xl') {
		grid-column: 1/11;
	}
}

.p-sdgs__text {
	grid-column: 8/11;
	@include mq('max', 'xl') {
		grid-column: 1/11;
	}
}