// ----------------------------------------
// path
// ----------------------------------------
$defaultPath: "/assets/" !default;

// ----------------------------------------
// spacer
// ----------------------------------------
//PC
$spacer-sm-thin: 0.5rem;
$spacer-sm: 1rem;
$spacer-sm-fat: 2rem;
$spacer-md-thin: 3rem;
$spacer-md: 4rem;
$spacer-md-fat: 6rem;
$spacer-lg: 7rem;
$spacer-xl: 10rem;
$spacer-xxl: 14rem;

//SP
$spacer-sm-thin-sp: 0.5rem;
$spacer-sm-sp: 1rem;
$spacer-sm-fat-sp: 1.5rem;
$spacer-md-thin-sp: 2rem;
$spacer-md-sp: 3rem;
$spacer-md-fat-sp: 4rem;
$spacer-lg-sp: 6rem;
$spacer-xl-sp: 8rem;
$spacer-xxl-sp: 10rem;

// ----------------------------------------
// font
// ----------------------------------------
// $font-meiryo: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo,
// 	メイリオ, sans-serif;
$font-base: Noto Sans JP,Hiragino Kaku Gothic Pro,ヒラギノ角ゴ Pro W3,メイリオ,Meiryo,ＭＳ Ｐゴシック,sans-serif;
$font-en: "Quicksand", sans-serif;

// ----------------------------------------
// color
// ----------------------------------------
$color-main: #202B5F;
$color-graytone01: #EEEEF0;
$color-graytone02: #DCE6F0;
$color-graytone03: #CFE5F4;
$color-graytone04: #8F94AE;
$color-white: #ffffff;
$color-black: #000000;
$color-attention: #D52912;


// ----------------------------------------
// font-weigth
// ----------------------------------------
$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

// ----------------------------------------
// icon
// ----------------------------------------
@function icon($character) {
	@return unquote('"') + unquote(str-insert($character, "\\", 1)) + unquote('"');
}
$icon-arrow-left: "e900";
$icon-arrow-right: "e901";
$icon-arrow-top: "e902";
$icon-contact: "e903";

// ----------------------------------------
// breakpoints
// ----------------------------------------
$breakpoints: (
	"sm": 380,
	"md": 750,
	"lg": 1024,
	"xl": 1200,
	"xxl": 1920,
) !default;

@mixin mq($mq, $bp1: lg, $bp2: lg) {
	$w1: map-get($breakpoints, $bp1);
	$w2: map-get($breakpoints, $bp2);
	$min1: "min-width: #{convert-em($w1, 1)}";
	$min2: "min-width: #{convert-em($w1, 1)}";
	$max1: "max-width: #{convert-em($w1)}";
	$max2: "max-width: #{convert-em($w2)}";

	@if $mq == min {
		@media print, screen and ($min1) {
			@content;
		}
	} @else if $mq == max {
		@media screen and ($max1) {
			@content;
		}
	} @else if $mq == min-max {
		@media screen and ($min2) and ($max2) {
			@content;
		}
	}
}
@function convert-em($width, $ge: 0) {
	@return "#{($width + $ge) / 16}em";
}

// ----------------------------------------
// cubic-bezier
// ----------------------------------------
$cubic-bezier: cubic-bezier(.16,.84,.44,1);

// ----------------------------------------
// mixin
// ----------------------------------------
@mixin object-fit($fit: fill, $position: null) {
	-o-object-fit: $fit;
	object-fit: $fit;
	@if $position {
		-o-object-position: $position;
		object-position: $position;
		font-family: "object-fit: #{$fit}; object-position: #{$position}";
	} @else {
		font-family: "object-fit: #{$fit}";
	}
}

@mixin fit-full($position: absolute) {
	position: $position;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin icomoon() {
	font-weight: normal;
	font-family: "icomoon";
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin line-height($comprehensive-size, $font-size) {
	line-height: $comprehensive-size / $font-size;
}

@mixin letter-spacing($trakking) {
	letter-spacing: $trakking / 1000 + em;
}

@mixin visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

@mixin leading-trim($line-height) {
	&::before,
	&::after {
		display: block;
		width: 0;
		height: 0;
		content: "";
	}
	&::before {
		margin-top: calc((1 - #{$line-height}) * 0.5em);
	}
	&::after {
		margin-bottom: calc((1 - #{$line-height}) * 0.5em);
	}
}
